<template>
  <v-card
    class="d-flex flex-wrap justify-space-between mb-5"
    max-width="100%"
    outlined
  >
    <v-list width="270" max-width="100%">
      <v-list-item two-line>
        <v-icon size="50" class="mr-5">mdi-notebook-edit</v-icon>
        <v-list-item-content>
          <v-list-item-title class="text-capitalize headline mb-1"
            >Karmanotin
            {{
              summaryHeaderInfo && "" && summaryHeaderInfo.brand
            }}</v-list-item-title
          >
          <v-list-item-subtitle
            >Model
            {{
              summaryHeaderInfo && summaryHeaderInfo.version
            }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="text-wrap"
            >Analytic Period:
            {{
              summaryHeaderInfo && summaryHeaderInfo.period
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- <v-list
      class="d-flex justify-space-between align-content-center flex-wrap"
      width="750"
      max-width="100%"
    >
      <v-list width="250">
        <v-list-item>
          <v-select
            dense
            v-model="selectedRxType"
            :items="outputY"
            label="Sales Unit"
            @change="onSelectedRxTypeChange"
          ></v-select>
        </v-list-item>
      </v-list>
    </v-list> -->
  </v-card>
</template>

<script>
export default {
  name: "summaryHeader",
  data: () => ({
    selectedRxType: null,
    // outputY: "sales",
    outputY: ["sales"],
  }),
  methods: {
    async init() {
      this.$store.commit("SET_RX_TYPES");
      // choose the first option as default value
      // console.log("this.rx_types[0]");
      // console.log(this.rx_types[0]);
      this.selectedRxType = this.rx_types[0];
      // this.selectedRxType = "sales";
      this.onSelectedRxTypeChange();
    },
    onSelectedRxTypeChange() {
      // set rx_type
      this.$store.commit("SET_SUMMARY_RX_TYPE", this.selectedRxType);

      // get metrics
      this.$store.commit("SET_SUMMARY_METRICS");

      // get charts
      this.$store.commit("SET_SUMMARY_CHARTS");
    },
  },
  computed: {
    language() {
      return this.$store.state.settings.language;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    summaryHeaderInfo() {
      return this.$store.getters.summaryHeaderInfo;
    },
    rx_types() {
      return this.$store.state.results.rx_types;
    },
  },
  watch: {
    language() {
      // re-render the charts with the change of language
      this.$store.commit("SET_SUMMARY_CHARTS");
    },
    theme() {
      // re-render the charts with the change of theme
      this.$store.commit("SET_SUMMARY_CHARTS");
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped></style>
